import dayjs from "dayjs";

export function updateUrlParams (key, value = null) {
    const url = new URL(window.location.href);
    if (value) {
        url.searchParams.set(key, value);
    } else {
        url.searchParams.delete(key);
    }
    history.pushState(null, document.title, url.toString());
}

export function getDateFromUrl () {
    let date = new URLSearchParams(location.search).get("date") ?? "month";
    let parse = date.split(",");
    const returnData = {
        date_begin: "",
        date_end: ""
    };

    if (Array.isArray(parse) && parse.length === 2) {
        returnData.date_begin = dayjs(parse[0], dateFormat()).toISOString();
        returnData.date_end = dayjs(parse[1], dateFormat()).toISOString();
    } else {
        returnData.date_begin = dayjs().startOf(date).toISOString();
        returnData.date_end = dayjs().endOf(date).toISOString();
    }

    return returnData;
}

export function dateFormat (withTime = false) {
    const dateFormats = {
        "m/d/Y": "MM/DD/YYYY",
        "d/m/Y": "DD/MM/YYYY",
        "m-d-Y": "MM-DD-YYYY",
        "d-m-Y": "DD-MM-YYYY",
    };

    const timeFormats = {
        "H:i": "HH:mm",
        "g:i A": "h:mm A"
    };

    const dateFormat = dateFormats[window.settingAppJs.dateFormat];

    return `${dateFormat}${withTime ? ` ${timeFormats[window.settingAppJs.timeFormat]}` : ""}`;
}

export const currencyFormatter = (value) => {
    function separate (val, separate = "") {
        // remove sign if negative
        let sign = 1;
        if (val < 0) {
            sign = -1;
            val = -val;
        }
        // trim the number decimal point if it exists
        let num = val.toString().includes(".")
            ? val.toString().split(".")[0]
            : val.toString();
        let len = num.toString().length;
        let result = "";
        let count = 1;

        for (let i = len - 1; i >= 0; i--) {
            result = num.toString()[i] + result;
            if (count % 3 === 0 && count !== 0 && i !== 0) {
                result = separate + result;
            }
            count++;
        }

        // add number after decimal point
        if (val.toString().includes(".")) {
            result = result + "." + val.toString().split(".")[1];
        }
        // return result with - sign if negative
        return sign < 0 ? "-" + result : result;
    }

    let separator = window.settingAppJs.currencyFormat.thousands_separator;
    if (separator === "blank-space") {
        separator = " ";
    }
    if (separator === "no-space") {
        separator = "";
    }

    let formattedValue = separate(
        Number.parseFloat(value).toFixed(window.settingAppJs.currencyFormat.decimals),
        separator,
    );

    return `${window.settingAppJs.currencyFormat.prefix}${formattedValue}`;
};

export function serializeQuery (params, prefix) {
    const query = Object.keys(params).map((key) => {
        const value = params[key];

        if (params.constructor === Array) key = `${prefix}[]`;
        else if (params.constructor === Object)
            key = prefix ? `${prefix}[${key}]` : key;

        if (typeof value === "object") return serializeQuery(value, key);
        else return `${key}=${encodeURIComponent(value)}`;
    });

    return [].concat.apply([], query).join("&");
}

export function fadeOutInElements ({
                                       elementToHideId,
                                       elementToShowId,
                                       fadeOutClass = "animate-fadeOut",
                                       fadeInClass = "animate-fadeIn",
                                   }) {
    const elToShow = document.getElementById(elementToShowId);
    const elToHide = document.getElementById(elementToHideId);

    if (!elToHide || !elToShow) return;

    const toggleVisibility = () => {
        elToHide.style.display = "none";
        elToShow.style.display = "block";
    };

    elToHide.classList.add(fadeOutClass);
    elToShow.classList.add(fadeInClass);

    elToHide.addEventListener("animationend", toggleVisibility, {once: true});
}

export function highlightSubstring (text, substring) {
    const escapedSubstring = escapeHtml(substring);
    const regex = new RegExp(`(${escapedSubstring})`, "gi");
    return text.replace(regex, "<b>$1</b>");
}

export function formatFileSize (bytes) {
    if (bytes === 0) return "0 Bytes";
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
}

function escapeHtml (text) {
    const map = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        "\"": "&quot;",
        "'": "&#039;",
    };
    return text.replace(/[&<>"']/g, function (m) {
        return map[m];
    });
}
